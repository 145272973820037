<template>
    <div
        class="mc-faq"
        :class="withBorder ? '--with-border' : ''"
        @click="toggleOpen()"
    >
        <div class="mc-faq__question">
            {{ faq.title }}
        </div>
        <img
            src="https://cdn.mrchadd.nl/ui-components/img/icons/chevron-down-circle.svg"
        />

        <div class="mc-faq__transition-container">
            <transition name="slide-down">
                <div class="mc-faq__answer" v-if="open">
                    <p
                        class="c-p"
                        v-for="(paragraph, index) in faq.paragraphs"
                        :key="index"
                    >
                        {{ paragraph }}
                    </p>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: "McFaq",
    props: {
        faq: Object,
        withBorder: Boolean,
    },
    data() {
        return {
            open: false,
        }
    },
    methods: {
        toggleOpen() {
            this.open = !this.open
        },
    },
}
</script>

<style lang="sass" scoped>
@import "../sass/variables-and-mixins"

.mc-faq
    margin-bottom: $standard-white-space
    padding: ($standard-white-space * 1.5) ($standard-white-space * 2)
    display: grid
    grid-template-columns: 1fr auto
    grid-column-gap: $standard-white-space
    align-items: center
    +border-radius
    background-color: $box-bg-color
    cursor: pointer

    &.\--with-border
        border: 1px solid $border-color

    &__question
        font-weight: $bold
        font-size: $large
        line-height: 1.6em

    &__transition-container
        overflow: hidden

    &__answer
        padding-top: $standard-white-space

        p + p
            margin-top: 20px

.slide-down-enter-active,
.slide-down-leave-active
    transition: transform 0.25s ease-out

.slide-down-enter-from,
.slide-down-leave-to
    transform: translateY(-100%)
</style>
