<template>
    <div class="c-checkbox">
        <input type="checkbox" :id="name" :value="name" />
        <label :for="name">
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
    },
}
</script>
