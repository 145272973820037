<template>
    <div class="v-icon-card" :class="inverted ? '--inverted' : ''">
        <div class="v-icon-card__image-wrapper">
            <img
                class="v-icon-card__image"
                :src="'ui-components/img/icons/' + filename + '.svg'"
            />
        </div>
        <p class="c-p --small u-mb-">{{ filename }}.svg</p>
    </div>
</template>

<script>
export default {
    name: "IconCard",
    props: {
        filename: String,
        inverted: Boolean,
    },
}
</script>

<style lang="sass" scoped>
.v-icon-card
    background-color: $white
    +border-radius
    padding: 0 5px

    &.\--inverted
        background-color: $inverted-bg-color
        color: $inverted-text-color

    &__image-wrapper
        height: 0
        padding-top: 100%
        position: relative

    &__image
        padding: 10px
        max-width: 100%
        max-height: 100%
        position: absolute
        +center
</style>
