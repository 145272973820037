<template>
    <span @click="showModal = true" v-if="!withoutTemplateTrigger">
        <slot name="trigger">Open modal</slot>
    </span>
    <teleport to="body" v-if="showModal">
        <div class="mc-modal__backdrop" @click="clickOnBackdrop()"></div>
        <transition name="slide-up-fade-in" appear>
            <div class="mc-modal" :class="size ? '--' + size : ''">
                <div
                    class="mc-modal__inner"
                    :class="{
                        '--with-padding': padding,
                        '--yellow': yellow,
                        '--light-yellow': lightYellow,
                    }"
                >
                    <slot></slot>
                </div>
                <button
                    class="mc-modal__close"
                    @click="closeModal()"
                    v-if="closableProp"
                >
                    <img
                        src="https://cdn.mrchadd.nl/ui-components/img/icons/close-button.svg"
                    />
                </button>
            </div>
        </transition>
    </teleport>
</template>

<script>
export default {
    emits: ["close"],
    data() {
        return {
            showModal: false,
            closableProp: this.closable,
        }
    },
    props: {
        padding: {
            type: Boolean,
            required: false,
        },
        size: {
            type: String,
        },
        yellow: {
            type: Boolean,
            required: false,
        },
        lightYellow: {
            type: Boolean,
            required: false,
        },
        closable: {
            type: Boolean,
            required: false,
            default: true,
        },
        closableByClickOutside: {
            type: Boolean,
            required: false,
            default: true,
        },
        withoutTemplateTrigger: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        escapeKeyListener(evt) {
            if (evt.keyCode === 27 && this.closableProp) {
                this.closeModal()
            }
        },
        clickOnBackdrop() {
            if (this.closableByClickOutside) {
                this.closeModal()
            }
        },
        closeModal() {
            this.$emit("close")
            this.showModal = false
        },
    },
    mounted() {
        document.addEventListener("keyup", this.escapeKeyListener)
    },
    unmounted() {
        document.removeEventListener("keyup", this.escapeKeyListener)
    },
}
</script>

<style lang="sass">
@import "../sass/variables-and-mixins.sass"

.mc-modal
    +center
    position: fixed
    width: 100%
    +z-index(modal)

    +mobile
        max-width: 90%

    +tablet-and-up
        max-width: 90%

    &.\--xs
        width: 400px

    &.\--small
        width: 619px

    &.\--medium
        width: 760px

    &.\--large
        width: 900px

    &.\--xl
        width: 1100px

    &__backdrop
        position: fixed
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: rgba($main-text-color, 0.75)
        +z-index(modal-backdrop)

    &__form
        position: relative

    &__inner
        background-color: $box-bg-color
        +border-radius
        max-height: 90vh // allow scrolling when content has more
        overflow-y: auto  // height than what fits in viewport

        img,
        video
            display: block
            width: 100%

        &.\--with-padding
            padding: $standard-white-space * 2

        &.\--yellow
            background-color: $yellow

        &.\--light-yellow
            background-color: $light-yellow

    &__close
        $size: 24px

        width: $size
        height: $size
        position: absolute
        cursor: pointer

        +mobile
            top: 10px
            right: 10px

        +tablet-and-up
            top: (-$size * 0.5)
            right: (-$size * 0.5)

.slide-up-fade-in-enter-active,
.slide-up-fade-in-leave-active
    transition: all 0.25s

.slide-up-fade-in-enter-from,
.slide-up-fade-in-leave-to
    opacity: 0
    transform: translateY(20px) translateX(-50%)
</style>
