<template>
    <div class="mc-single-complete">
        <input
            @focus="onChange"
            @input="onChange"
            v-model="search"
            type="text"
            :placeholder="placeholder"
            class="c-input u-pr+"
            :class="inputClass"
        />
        <div class="mc-single-complete__search-icon">
            <img
                v-if="search === ''"
                :src="$root.x.cdnPath + '/icons/search.svg'"
            />
            <img
                v-else
                @click="reset()"
                :src="$root.x.cdnPath + '/icons/trash-can.svg'"
                class="mc-single-complete__search-reset"
            />
        </div>
    </div>
    <ul v-if="results.length > 0" class="mc-single-complete__list">
        <li
            v-for="(result, index) in results"
            @click="setResult(result)"
            :key="index"
            class="mc-single-complete__list-item"
        >
            {{ result.name }}
        </li>
    </ul>
    <slot v-if="showNoResults"></slot>

    <teleport to="body" v-if="results.length > 0">
        <div class="mc-single-complete__backdrop" @click="reset()"></div>
    </teleport>
</template>

<script>
export default {
    name: "McSingleComplete",
    data() {
        return {
            search: "",
            results: [],
        }
    },
    props: {
        items: Array,
        placeholder: String,
        selectedValue: Object,
        position: {
            type: String,
            required: false,
            default: "relative",
        },
        inputClass: {
            type: String,
            required: false,
            default: "",
        },
    },
    emits: ["select"],
    computed: {
        showNoResults() {
            return this.results.length === 0 && this.search !== ""
        },
    },
    methods: {
        onChange() {
            this.results = this.items.filter((item) =>
                item.name.toLowerCase().includes(this.search.toLowerCase())
            )
        },
        setResult(result) {
            // Put name in input field
            this.search = result.name

            // Empty results so select closes
            this.results = []

            // Send result to parent
            this.$emit("select", result)
        },
        reset() {
            this.search = ""
            this.results = []
        },
    },
    mounted() {
        if (this.selectedValue !== undefined) {
            this.search = this.selectedValue.name
        }
    },
}
</script>

<style lang="sass" scoped>
.mc-single-complete
    position: relative
    +z-index(modal)

    &__search-icon
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: 1px
        display: block
        padding: 10px

    &__search-reset
        cursor: pointer

    &__list
        list-style: none
        +box-shadow
        border-radius: 10px
        overflow: hidden
        display: grid
        background-color: $light-purple
        grid-gap: 1px
        +z-index(dropdown)
        position: v-bind(position)

    &__list-item
        padding: 9px 35px
        cursor: pointer
        font-size: $small
        background-color: white

        &:last-child
            border: none

        &.\--active,
        &:hover
            background-color: $light-yellow

    &__backdrop
        position: fixed
        top: 0
        bottom: 0
        left: 0
        right: 0
</style>
