<template>
    <mc-alerts />
    <div class="l-wrapper --large">
        <router-view />
    </div>
</template>

<script>
import McAlerts from "@/assets/ui-components/vue-components/McAlerts.vue"

export default {
    components: {
        McAlerts,
    },
}
</script>
