<template>
    <div class="l-grid --2-cols u-mb">
        <div v-html="code"></div>
        <div>
            <pre><code>{{ code }}</code></pre>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        code: {
            required: true,
            type: String,
        },
    },
}
</script>

<style lang="sass">
pre
    background-color: lighten($yellow, 23%)
    padding: 8px 12px
    white-space: pre-wrap
    font-size: 13px

code
    font-family: Menlo, Courier New, monospace
    line-height: 1.5
</style>
