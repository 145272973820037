<template>
    <pre><code>{{ code }}</code></pre>
</template>

<script>
export default {
    props: {
        code: {
            required: true,
            type: String,
        },
    },
}
</script>
