<template>
    <div class="mc-alerts">
        <transition-group name="list">
            <div
                v-for="alert in $store.state.alerts.alerts"
                :key="alert.uid"
                class="mc-alerts__alert"
                :class="'--' + alert.alertType"
            >
                <div class="mc-alerts__image">
                    <img :src="imageSrc(alert.image, alert.alertType)" />
                </div>
                <div>
                    <h3 class="c-heading --small" v-if="alert.title">
                        {{ alert.title }}
                    </h3>
                    <p class="c-p">{{ alert.text }}</p>
                </div>
                <button
                    class="mc-alerts__close"
                    @click="
                        $store.commit({
                            type: 'closeAlert',
                            uid: alert.uid,
                        })
                    "
                    v-if="alert.closable"
                >
                    <img :src="$root.x.cdnPath + '/icons/close-button.svg'" />
                </button>
            </div>
        </transition-group>
    </div>
</template>

<script>
export default {
    methods: {
        imageSrc(image, type) {
            if (image === undefined || image === "") {
                return this.$root.x.cdnPath + "/icons/alert-" + type + ".svg"
            } else {
                return image
            }
        },
    },
}
</script>

<style lang="sass" scoped>
.mc-alerts
    position: fixed
    width: 400px
    max-width: 90%
    +z-index(alert)

    +mobile
        top: 15px
        right: 15px

    +tablet-and-up
        top: $standard-white-space * 1.5
        right: $standard-white-space * 1.5

    &__alert
        position: relative
        color: $inverted-text-color
        padding: $standard-white-space
        font-size: $small
        font-weight: $semi-bold
        +border-radius
        margin-bottom: $standard-white-space * 1.5
        display: grid
        grid-template-columns: auto 1fr
        grid-gap: 10px
        align-items: center
        +box-shadow

        &.\--info
            background-color: white
            color: $main-text-color

        &.\--error
            background-color: $red

        &.\--success
            background-color: $green

    &__image
        width: 24px
        height: 24px
        position: relative

        img
            max-width: 100%
            max-height: 100%
            position: absolute
            +center

    &__close
        $size: 24px

        top: (-$size * 0.5)
        right: (-$size * 0.5)
        width: $size
        height: $size
        position: absolute
        cursor: pointer

.list-enter-active,
.list-leave-active
    transition: all 0.5s ease-in-out

.list-enter-from
    transform: translateX(100%)

.list-leave-to
    opacity: 0
</style>
