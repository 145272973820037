<template>
    <div class="mc-autocomplete" v-if="items.length > 0">
        <div class="mc-autocomplete__input-wrapper">
            <input
                class="c-input"
                :class="
                    search === '' && showSearchIcon ? '--search' : '--searching'
                "
                type="text"
                v-model="search"
                @input="onChange"
                @keyup.down="onArrowDown"
                @keyup.up="onArrowUp"
                @keyup.enter="onEnter"
                :placeholder="placeholder"
            />
            <img
                v-if="search !== ''"
                :src="$root.x.cdnPath + '/icons/trash-can.svg'"
                @click="resetSearch()"
                class="mc-autocomplete__search-reset"
                alt=""
            />
        </div>
        <ul
            class="mc-autocomplete__list"
            v-show="results.length > 0"
            :style="styleObject"
        >
            <li
                class="mc-autocomplete__list-item"
                v-for="(result, index) in results"
                :key="index"
                @click="setResult(result)"
                :class="resultClass(result, index)"
            >
                <template v-if="itemsAsObject">
                    {{ result.name }}
                </template>
                <template v-else>
                    {{ result }}
                </template>
            </li>
        </ul>
        <ul
            class="mc-autocomplete__list"
            v-show="results.length === 0 && search != ''"
        >
            <li class="mc-autocomplete__no-results">
                Geen resultaten gevonden voor <b>{{ search }}</b>
            </li>
        </ul>
    </div>

    <p class="c-p --error u-text-center" v-else>
        Er zijn geen opties om uit te kiezen
    </p>
</template>

<script>
export default {
    name: "McAutocomplete",
    data() {
        return {
            results: [],
            selectedResults: [],
            search: "",
            arrowCounter: -1,
            styleObject: {
                gridTemplateColumns:
                    "repeat(" + this.numberOfColumns + ", 1fr)",
            },
        }
    },
    props: {
        items: Array,
        // Items that were selected on initialization (when updating a selection for example)
        initSelectedItems: {
            type: Array,
            required: false,
            default: () => [],
        },
        placeholder: String,
        showSearchIcon: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Have select dropdown open on initial state (so before user types something)
        initSelectOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
        // Keep select dropdown open after selecting an option
        keepSelectOpen: {
            type: Boolean,
            required: false,
            default: false,
        },
        numberOfColumns: {
            type: Number,
            required: false,
            default: 1,
        },
        // You can pass the items as a flat array of strings, or as an array
        // of objects that needs a `name` key
        itemsAsObject: {
            type: Boolean,
            default: false,
            required: false,
        },
        multiple: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    methods: {
        onChange() {
            if (this.itemsAsObject) {
                this.results = this.items.filter((item) => {
                    return (
                        item.name
                            .toLowerCase()
                            .indexOf(this.search.toLowerCase()) > -1
                    )
                })
            } else {
                this.results = this.items.filter((item) => {
                    return (
                        item.toLowerCase().indexOf(this.search.toLowerCase()) >
                        -1
                    )
                })
            }
        },
        setResult(result) {
            if (this.selectedResults.includes(result)) {
                for (var i = 0; i < this.results.length; i++) {
                    if (this.selectedResults[i] === result) {
                        // Store in local array
                        this.selectedResults.splice(i, 1)
                        // Send result to parent
                        this.$emit("unselect", i)
                        break
                    }
                }
            } else {
                if (!this.multiple) {
                    this.selectedResults = []
                }
                // Store in local array
                this.selectedResults.push(result)
                // Send result to parent
                this.$emit("select", result)
            }
        },
        onArrowDown() {
            if (this.arrowCounter < this.results.length - 1) {
                this.arrowCounter++
            }
        },
        onArrowUp() {
            if (this.arrowCounter > -1) {
                this.arrowCounter--
            }
        },
        onEnter() {
            if (this.results.length > 0 && this.arrowCounter > -1) {
                this.setResult(this.results[this.arrowCounter])

                if (!this.keepSelectOpen) {
                    this.arrowCounter = -1
                }
            }
        },
        resetSearch() {
            this.search = ""
            if (!this.keepSelectOpen) {
                this.results = []
            } else {
                this.results = this.items
            }
            this.arrowCounter = -1
        },
        resultClass(result, index) {
            let value = ""

            if (index === this.arrowCounter) {
                value = value + "--active "
            }

            if (this.selectedResults.includes(result)) {
                value = value + "--selected "
            }
            return value
        },
    },
    mounted() {
        if (this.initSelectOpen) {
            this.results = this.items
        }

        if (this.initSelectedItems) {
            for (let i = 0; i < this.initSelectedItems.length; i++) {
                if (this.items.includes(this.initSelectedItems[i])) {
                    this.setResult(this.initSelectedItems[i])
                }
            }
        }
    },
}
</script>

<style lang="sass" scoped>
.mc-autocomplete
    &__input-wrapper
        position: relative

    &__search-reset
        position: absolute
        top: 50%
        transform: translateY(-50%)
        right: 2px
        display: block
        padding: 10px
        cursor: pointer
        width: auto // to override width: 100% when autocomplete is inside a modal

    &__list
        list-style: none
        // padding: 0 0 5px 0
        +box-shadow
        border-radius: 0 0 10px 10px
        overflow: hidden
        display: grid
        background-color: $light-purple
        grid-gap: 1px

    &__list-item
        padding: 9px 35px
        white-space: nowrap
        cursor: pointer
        font-size: $small
        background-color: white

        &:last-child
            border: none

        &.\--active,
        &:hover
            background-color: $light-yellow

        &.\--selected
            +cdn-background-image("icons/checkmark-green.svg")
            background-position: left 10px center
            background-size: 16px auto
            font-weight: $bold

    &__no-results
        padding: 7px 20px
        font-size: $small

    .c-input
        border-radius: 10px 10px 0 0
</style>
