import { createStore } from "vuex"

import alerts from "../assets/ui-components/vuex-modules/alerts.js"
import i18n from "./modules/i18n.js"

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        alerts: alerts,
        i18n: i18n,
    },
})
