<template>
    <div class="v-cookie-bar" v-if="!localStorageObject.accepted">
        <div class="v-cookie-bar__initial-state" v-if="!showSettings">
            <p class="v-cookie-bar__intro c-p">
                {{ i18n.mainSentence }}
            </p>

            <button
                class="c-button --secondary --small"
                @click="showSettings = true"
            >
                {{ i18n.settingsButton }}
            </button>

            <button class="c-button --primary --small" @click="accept()">
                {{ i18n.acceptButton }}
            </button>
        </div>

        <div class="v-cookie-bar__settings" v-if="showSettings">
            <h2 class="c-heading --medium u-mb">
                {{ i18n.settingsHeading }}
            </h2>
            <div class="l-grid --2-cols --large-gap u-mb">
                <p class="c-p --small u-hide@mobile">
                    {{ i18n.settingsIntro }}
                </p>
                <div>
                    <div class="l-flex --space-between u-mb-">
                        <button
                            class="v-cookie-bar__type"
                            @click="
                                this.expanded.functional = !this.expanded
                                    .functional
                            "
                            :class="{ '--expanded': expanded.functional }"
                        >
                            {{ i18n.functionalCookiesLabel }}
                        </button>
                        <div class="c-toggle-input">
                            <input
                                type="checkbox"
                                v-model="localStorageObject.functional"
                                id="functional"
                                disabled
                            />
                            <label for="functional"></label>
                        </div>
                    </div>
                    <p class="c-p --small u-mb" v-if="expanded.functional">
                        {{ i18n.functionalCookiesText }}
                    </p>

                    <div class="l-flex --space-between u-mb-">
                        <button
                            class="v-cookie-bar__type"
                            @click="
                                this.expanded.analytical = !this.expanded
                                    .analytical
                            "
                            :class="{ '--expanded': expanded.analytical }"
                        >
                            {{ i18n.analyticalCookiesLabel }}
                        </button>
                        <div class="c-toggle-input">
                            <input
                                type="checkbox"
                                v-model="localStorageObject.analytical"
                                id="analytical"
                            />
                            <label for="analytical"></label>
                        </div>
                    </div>
                    <p class="c-p --small u-mb" v-if="expanded.analytical">
                        {{ i18n.analyticalCookiesText }}
                    </p>

                    <div
                        class="l-flex --space-between u-mb-"
                        v-if="marketingCookies"
                    >
                        <button
                            class="v-cookie-bar__type"
                            @click="
                                this.expanded.marketing = !this.expanded
                                    .marketing
                            "
                            :class="{ '--expanded': expanded.marketing }"
                        >
                            {{ i18n.marketingCookiesLabel }}
                        </button>
                        <div class="c-toggle-input">
                            <input
                                type="checkbox"
                                v-model="localStorageObject.marketing"
                                id="marketing"
                            />
                            <label for="marketing"></label>
                        </div>
                    </div>
                    <p class="c-p --small u-mb" v-if="expanded.marketing">
                        {{ i18n.marketingCookiesText }}
                    </p>
                </div>
            </div>
            <div class="u-text-center">
                <button class="c-button --primary --small" @click="accept()">
                    {{ i18n.saveButton }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "McCookieBar",
    data() {
        return {
            showSettings: false,
            localStorageObject: {
                accepted: false,
                functional: true,
                analytical: true,
                marketing: true,
            },
            expanded: {
                functional: false,
                analytical: false,
                marketing: false,
            },
            i18n: {
                mainSentence:
                    "Mr. Chadd maakt gebruik van cookies om de functionaliteit te waarborgen.",
                settingsButton: "Instellingen",
                acceptButton: "Accepteren",
                settingsHeading: "Deze website maakt gebruik van cookies",
                settingsIntro:
                    "We gebruiken cookies om content en advertenties te personaliseren, om functies voor sociale media te bieden en om het websiteverkeer te analyseren. Ook delen we informatie over uw gebruik van onze website met onze partners voor sociale media, adverteren en analyse. Deze partners kunnen deze gegevens combineren met andere informatie die u aan ze heeft verstrekt of die ze hebben verzameld op basis van uw gebruik van hun services.",
                functionalCookiesLabel: "Functionele cookies",
                functionalCookiesText:
                    "Functionele cookies helpen onze website bruikbaarder te maken, door basisfuncties als paginanavigatie en toegang tot beveiligde gedeelten van de website mogelijk te maken. Zonder deze cookies kan de website niet naar behoren werken.",
                analyticalCookiesLabel: "Analytische cookies",
                analyticalCookiesText:
                    "Analytische cookies worden gebruikt om bijvoorbeeld bezoekersstatistieken bij te houden. Op deze manier krijgen wij beter inzicht in het functioneren van de website. Analytische cookies hebben nauwelijks gevolgen voor uw privacy.",
                marketingCookiesLabel: "Marketing cookies",
                marketingCookiesText:
                    "Marketingcookies worden gebruikt om bezoekers te volgen wanneer ze verschillende websites bezoeken. Hun doel is advertenties weergeven die zijn toegesneden op en relevant zijn voor de individuele gebruiker. Deze advertenties worden zo waardevoller voor uitgevers en externe adverteerders.",
                saveButton: "Opslaan",
            },
        }
    },
    props: {
        // Marketing cookies are optional
        marketingCookies: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    methods: {
        accept() {
            this.localStorageObject.accepted = true

            // Save user input into localStorage
            localStorage.setItem(
                "CookieBar",
                JSON.stringify(this.localStorageObject)
            )
        },
    },
    mounted() {
        // Check if user already accepted
        if (localStorage.getItem("CookieBar") !== null) {
            this.localStorageObject = JSON.parse(
                localStorage.getItem("CookieBar")
            )
        }
    },
}
</script>

<style lang="sass" scoped>
@import "../sass/variables-and-mixins"

.v-cookie-bar
    position: fixed
    background-color: $box-bg-color
    +border-radius
    border: 2px solid $border-color

    +mobile
        width: 100%
        bottom: 0

    +tablet-and-up
        left: 50%
        transform: translateX(-50%)
        bottom: 20px

    &__initial-state
        padding: $standard-padding * 0.5
        display: grid
        grid-gap: 20px
        align-items: center

        +mobile
            grid-template-columns: 1fr 1fr
            grid-template-rows: auto auto

        +tablet-and-up
            grid-template-columns: auto auto auto

    &__intro
        +mobile
            grid-row: 1 / 2
            grid-column: 1 / 3
            text-align: center

    &__settings
        padding: $standard-padding

        +tablet-and-up
            max-width: 628px

    &__type
        position: relative
        padding-left: 24px

        &::before
            content: ''
            background-image: url("https://cdn.mrchadd.nl/ui-components/img/icons/chevron-right.svg")
            background-repeat: no-repeat
            background-position: center
            position: absolute
            left: 0
            width: 20px
            height: 20px
            transition: transform 0.2s ease

        &.\--expanded
            &::before
                transform: rotate(90deg)
</style>
