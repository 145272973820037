export default {
    state: {
        alerts: [],
    },
    mutations: {
        addAlert(state, payload) {
            // Generate unique ID to be able to identify the alert
            const uid = Math.random().toString(16).slice(2)

            // Set duration to the default duration when duration is not specified
            if (payload.duration === undefined) {
                payload.duration = 5000
            }

            // Set closable to default when not specified
            if (payload.closable === undefined) {
                payload.closable = true
            }

            // Add uid to payload object
            payload.uid = uid

            // Add alert object to alerts array
            state.alerts.push(payload)

            // Remove alert object from alerts array
            if (!payload.infiniteDuration) {
                setTimeout(
                    function () {
                        for (let i = 0; i < state.alerts.length; i++) {
                            if (state.alerts[i].uid === uid) {
                                state.alerts.splice(i, 1)
                            }
                        }
                    }.bind(state),
                    payload.duration
                )
            }
        },
        closeAlert(state, payload) {
            for (let i = 0; i < state.alerts.length; i++) {
                if (state.alerts[i].uid === payload.uid) {
                    state.alerts.splice(i, 1)
                }
            }
        },
    },
}
