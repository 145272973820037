<template>
    <div class="mc-tooltip" :style="styles">
        <div
            class="mc-tooltip__icon-wrapper"
            :style="block ? 'display: block' : ''"
        >
            <slot name="trigger" v-if="$slots.trigger"> </slot>
            <div v-else class="mc-tooltip__icon">i</div>
        </div>
        <div
            class="mc-tooltip__popup"
            :class="[bottom ? '--bottom' : '', left ? '--left' : '']"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "McTooltip",
    props: {
        text: String,
        bottom: Boolean,
        left: Boolean,
        flex: Boolean, // sometimes the vertical positioning is off, then it can help to use this prop in your component
        block: Boolean, // sometimes the horizontal positioning is off, then it can help to use this prop in your component
    },
    computed: {
        styles() {
            return [
                this.flex ? "display: flex" : "",
                this.block ? "display: block" : "",
            ]
        },
    },
}
</script>

<style lang="sass" scoped>
@import "../sass/variables-and-mixins"

.mc-tooltip
    position: relative
    display: inline-block
    word-break: normal

    &__icon-wrapper
        display: inline-block
        cursor: pointer
        padding: 1px

        &:hover
            + .mc-tooltip__popup
                opacity: 1
                transform: translateY(5px) translateX(0)

    &__icon
        $size: 18px

        height: $size
        width: $size
        line-height: $size
        background-color: $yellow
        border-radius: 50%
        text-align: center
        font-weight: $semi-bold
        font-size: 14px

    &__popup
        opacity: 0
        transition: all 0.2s ease-out
        position: absolute
        padding: 13px 15px
        background-color: $white
        border-radius: 20px 20px 20px 5px
        border: 1px solid $border-color
        +box-shadow
        min-width: 200px
        max-width: 250px
        bottom: calc(100% + 10px)
        font-size: $mini
        pointer-events: none
        color: #B2ACC8
        font-weight: $semi-bold
        line-height: 1.5

        &.\--bottom
            bottom: calc(100% - 90px)
            border-radius: 5px 20px 20px 20px

        &.\--left
            right: 100%
            border-radius: 20px 20px 5px 20px
</style>
