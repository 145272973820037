// Objects should always have the same name as the component they are used in

export default {
    state: {
        // UI-components
        mcSetPassword: {
            newPasswordLabel: "Nieuwe wachtwoord",
            passwordLabel: "Wachtwoord",
            inputPlaceholder: "Wachtwoord",
            tooShort: "min. 8 tekens",
            noLowercase: "1 kleine letter",
            noUppercase: "1 hoofdletter",
            noNumber: "1 cijfer",
            noSymbol: "1 symbool",
            repeatLabel: "herhalen",
            notEqual: "Wachtwoorden komen niet overeen",
        },
    },
}
