<template>
    <div class="u-mb+" style="margin-top: 32px">
        <h1>UI Components style guide</h1>
    </div>

    <div class="v-menu">
        <a class="c-link --underline" href="#colors">Colors</a>
        <a class="c-link --underline" href="#icons">Icons</a>
        <a class="c-link --underline" href="#css-components">CSS components</a>
        <a class="c-link --underline" href="#vue-components">Vue components</a>
    </div>

    <div id="colors" class="u-p+"></div>

    <h2 class="c-heading --medium u-mb">Colors</h2>

    <div class="u-mb+">
        <div class="v-colors">
            <div
                class="v-colors__color"
                v-for="(color, index) in colors"
                :key="index"
            >
                <div
                    class="v-colors__box"
                    :style="'background-color: ' + color.hex"
                ></div>
                {{ color.name }}<br />
                {{ color.hex }}
            </div>
        </div>
    </div>

    <div id="icons" class="u-p+"></div>

    <h2 class="c-heading --medium u-mb">Icons</h2>

    <p class="c-p u-mb-">Use as CSS background-image like this:</p>
    <div class="u-mb">
        <pre><code>+cdn-background-image("icons/xxxxx.svg")</code></pre>
    </div>

    <p class="c-p u-mb-">or as inline HTML image like this:</p>
    <div class="u-mb+">
        <pre><code>&lt;img :src="$root.x.cdnPath + '/icons/xxxxx.svg'" /&gt;</code></pre>
    </div>
    <div class="v-icons">
        <icon-card filename="add-white" inverted />
        <icon-card filename="add" />
        <icon-card filename="alert-error-red" inverted />
        <icon-card filename="alert-error" inverted />
        <icon-card filename="alert-info" />
        <icon-card filename="alert-success" inverted />
        <icon-card filename="apple" inverted />
        <icon-card filename="attachment" />
        <icon-card filename="calendar" />
        <icon-card filename="camera-white" inverted />
        <icon-card filename="camera" />
        <icon-card filename="chat-bubble-new-white" inverted />
        <icon-card filename="chat-bubble-white" inverted />
        <icon-card filename="checkmark-green" />
        <icon-card filename="checkmark-white" inverted />
        <icon-card filename="checkmark" />
        <icon-card filename="chevron-down-circle" />
        <icon-card filename="chevron-down" />
        <icon-card filename="chevron-left" />
        <icon-card filename="chevron-right" />
        <icon-card filename="chevron-white-left" inverted />
        <icon-card filename="chevron-white-right" inverted />
        <icon-card filename="classes" />
        <icon-card filename="close-button" />
        <icon-card filename="close" />
        <icon-card filename="clusters" />
        <icon-card filename="courses" />
        <icon-card filename="crop" />
        <icon-card filename="dropdown-arrow-lighter" />
        <icon-card filename="dropdown-arrow" />
        <icon-card filename="edit" />
        <icon-card filename="export" />
        <icon-card filename="facebook" inverted />
        <icon-card filename="flip-h-white" inverted />
        <icon-card filename="flip-v-white" inverted />
        <icon-card filename="hamburger" />
        <icon-card filename="image" />
        <icon-card filename="linked-in" />
        <icon-card filename="loader" />
        <icon-card filename="microphone" />
        <icon-card filename="notification-check" inverted />
        <icon-card filename="notification-error" inverted />
        <icon-card filename="notification-info-black" />
        <icon-card filename="notification-info" inverted />
        <icon-card filename="paperclip-black" />
        <icon-card filename="pencil" />
        <icon-card filename="plus" />
        <icon-card filename="quick-reply" />
        <icon-card filename="recording-stop" />
        <icon-card filename="reply" />
        <icon-card filename="rotate-ccw-white" inverted />
        <icon-card filename="rotate-cw-white" inverted />
        <icon-card filename="search" />
        <icon-card filename="send-message" />
        <icon-card filename="spinner-black" />
        <icon-card filename="spinner-white" inverted />
        <icon-card filename="t-m-black" />
        <icon-card filename="t-m-white" inverted />
        <icon-card filename="tests-black" />
        <icon-card filename="tests-inactive" />
        <icon-card filename="tests" />
        <icon-card filename="three-dots" />
        <icon-card filename="trash-can" />
        <icon-card filename="upload" />
    </div>

    <div id="css-components" class="u-p+"></div>

    <h2 class="c-heading --medium u-mb">CSS components</h2>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Buttons</h2>

        <code-block
            code='<button class="c-button --primary">
    Primary button
</button>'
        />

        <p class="c-p u-mb-">Primary button with loader:</p>

        <code-block
            code='<button disabled class="c-button --primary --loading">
    Primary button (with loading indicator)
</button>'
        />

        <code-block
            code='<button class="c-button --primary --small">
    Primary button (small)
</button>'
        />

        <p class="c-p u-mb-">Primary button (small) with loader:</p>

        <code-block
            code='<button disabled class="c-button --primary --small --loading">
    Primary button (with loading indicator)
</button>'
        />

        <code-block
            code='<button class="c-button --primary --full-width">
    Primary button (full-width)
</button>'
        />

        <code-block
            code='<button class="c-button --primary" disabled>
    Primary button (disabled)
</button>'
        />

        <code-block
            code='<button class="c-button --primary --with-icon">
    <img src="/ui-components/img/emojis/🚀.png">
    Primary button (with icon)
</button>'
        />

        <code-block
            code='<button class="c-button --primary-inverted">
    Primary button (inverted)
</button>'
        />

        <code-block
            code='<button class="c-button --secondary">
    Secondary button
</button>'
        />

        <p class="c-p u-mb-">Secondary button with loader:</p>

        <code-block
            code='<button disabled class="c-button --secondary --loading">
    Secondary button (disabled)
</button>'
        />

        <code-block
            code='<button class="c-button --secondary" disabled>
    Secondary button (disabled)
</button>'
        />

        <code-block
            code='<button class="c-button">
    Text button
</button>'
        />

        <p class="c-p u-mb">Icon button primary:</p>
        <code-block
            code='<button class="c-button --primary --round-icon-button">
    <img src="ui-components/img/icons/chevron-white-right.svg" />
</button>'
        />

        <p class="c-p u-mb">Icon button secondary:</p>
        <code-block
            code='<button class="c-button --secondary --round-icon-button">
    <img src="ui-components/img/icons/chevron-right.svg" />
</button>'
        />

        <p class="c-p u-mb">Button group:</p>
        <code-block
            code='<div class="c-button-group">
    <button class="c-button --secondary">Link</button>
    <button class="c-button --secondary">Link</button>
    <button class="c-button --secondary">Link</button>
    <button class="c-button --secondary">Link</button>
    <button class="c-button --secondary">Link</button>
</div>
'
        />
    </div>

    <h2 class="c-heading --small u-mb">Card</h2>

    <code-block
        code='<div class="c-card --box-bg --with-padding">
    <h2 class="c-heading u-mb">White card with padding</h2>

    <p>
        A card can contain any kind of generic content.
    </p>
</div>
'
    />

    <code-block
        code='<div class="c-card --yellow-bg --with-padding">
    <h2 class="c-heading u-mb">Yellow card with padding</h2>

    <p>
        A card can contain any kind of generic content.
    </p>
</div>
'
    />

    <code-block
        code='<div class="c-card --box-bg --with-large-padding">
    <h2 class="c-heading u-mb">White card (with large padding)</h2>

    <p>
        A card can contain any kind of generic content.
    </p>
</div>
'
    />

    <code-block
        code='<div class="c-card --box-bg --with-padding --with-box-shadow">
    <h2 class="c-heading u-mb">White card (with box shadow)</h2>

    <p>
        This card has a very subtle box-shadow
    </p>
</div>
'
    />

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Chat bubble component</h2>

        <code-block
            code='<p class="c-chat-bubble --black">Gefeliciteerd!</p>'
        />

        <code-block
            code='<p class="c-chat-bubble --yellow">Je hebt het target van fase 1 gehaald! 👏</p>'
        />

        <code-block
            code='<p class="c-chat-bubble --grey">We gaan door naar fase 2!</p>'
        />
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Chat status indicator component</h2>

        <code-block
            code='<div class="c-chat-status-indicator">
    <p class="--handover">Chat met Wouter</p>
</div>'
        />

        <code-block
            code='<div class="c-chat-status-indicator">
    <p class="--new-messages">Nieuwe berichten</p>
</div>'
        />
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Headings</h2>

        <code-block
            code='<h2 class="c-heading --small">Small heading 20px</h2>'
        />

        <code-block
            code='<h2 class="c-heading --medium">Medium-sized heading 24px</h2>'
        />

        <code-block
            code='<h2 class="c-heading --medium --bolder">Medium-sized heading 24px (bolder)</h2>'
        />

        <code-block
            code='<h2 class="c-heading --large">Large heading 30px</h2>'
        />

        <code-block
            code='<h2 class="c-heading --large --bolder">Large heading 30px (bolder)</h2>'
        />

        <code-block code='<h2 class="c-heading --xl">XL heading 36px</h2>' />
        <code-block
            code='<h2 class="c-heading --xl --bolder">XL heading 36px (bolder)</h2>'
        />

        <code-block code='<h2 class="c-heading --xxl">XXL heading 50px</h2>' />
        <code-block
            code='<h2 class="c-heading --xxl --bolder">XXL heading 50px (bolder)</h2>'
        />
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Select box</h2>

        <div class="u-mb+">
            <code-block
                code='<div class="c-select">
    <select>
        <option>2hvb</option>
        <option>1vwo</option>
        <option>vm2a</option>
    </select>
</div>'
            />
        </div>
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Toggle input</h2>

        <div class="u-mb+">
            <code-block
                code='<div class="c-toggle-input">
    <input
        type="checkbox"
        id="example"
    >
    <label for="example"></label>
</div>'
            />
        </div>
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">On/off indicator</h2>

        <code-block
            code='<ul>
    <li class="c-on-off-indicator --on">Rey McDermott</li>
    <li class="c-on-off-indicator --on">Keara Feil</li>
    <li class="c-on-off-indicator --off">Karianne Lynch</li>
    <li class="c-on-off-indicator --off">Matt Gorczany</li>
</ul>

<p class="c-on-off-indicator --off">Neil Bode</p>'
        />
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Links</h2>

        <code-block
            code='<a href="#" class="c-link --underline">
    A link with underline
</a>'
        />

        <code-block
            code='<a href="#" class="c-link --small">
    A link with a small font-size
</a>'
        />
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Inputs</h2>
        <code-block
            code='<label class="c-label">
    Input label
</label>'
        />

        <code-block
            code='<input
    class="c-input"
    type="text"
    placeholder="Input field"
/>'
        />

        <code-block
            code='<input type="text" class="c-input --search --small" />'
        />

        <code-block
            code='<input type="text" class="c-input --reset --small" />'
        />

        <code-block
            code='<input
    class="c-input --error"
    type="text"
    placeholder="Input error state"
/>'
        />

        <code-block code='<textarea class="c-input"></textarea>' />

        <code-block
            code='<div class="l-input-button-wrapper">
    <input class="c-input" type="text" />
    <button class="c-button --primary">Verzenden</button>
</div>'
        />
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Paragraph component</h2>
        <code-block
            code='<p class="c-p">
    Lorem, ipsum dolor sit, amet consectetur adipisicing elit. Dolor
    soluta, culpa dicta ipsa vero ad eius sed iusto animi nobis nam
    quis ab temporibus ipsam voluptate nesciunt earum quod ex!
</p>'
        />
        <code-block
            code='<p class="c-p --small">
    Lorem, ipsum dolor sit, amet consectetur adipisicing elit. Dolor
    soluta, culpa dicta ipsa vero ad eius sed iusto animi nobis nam
    quis ab temporibus ipsam voluptate nesciunt earum quod ex!
</p>'
        />
        <code-block
            code='<p class="c-p --error">
    Lorem, ipsum dolor sit, amet consectetur adipisicing elit. Dolor
    soluta, culpa dicta ipsa vero ad eius sed iusto animi nobis nam
    quis ab temporibus ipsam voluptate nesciunt earum quod ex!
</p>'
        />
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Table component</h2>
        <code-block
            code='<table class="c-table">
    <tr>
        <th>Heading 0.1</th>
        <th>Heading 0.2</th>
        <th>Heading 0.3</th>
    </tr>
    <tr>
        <td>Table cell 1.1</td>
        <td>Table cell 1.2</td>
        <td>Table cell 1.3</td>
    </tr>
    <tr>
        <td>Table cell 2.1</td>
        <td>Table cell 2.2</td>
        <td>Table cell 2.3</td>
    </tr>
</table>'
        />
    </div>

    <div id="vue-components" class="u-p+"></div>

    <h2 class="c-heading --medium u-mb">Vue components</h2>

    <div class="c-card --yellow-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">FAQ component</h2>

        <div class="l-grid --2-cols u-mb">
            <div>
                <div v-for="faq in faqs" :key="faq.title">
                    <McFaq :faq="faq" />
                </div>
            </div>
            <div>
                <p class="c-p u-mb">
                    This component has one prop, called faq, this contains an
                    object that has a `title` object and a `paragraphs` array.
                </p>
                <vue-code-block class="u-mb" code='<McFaq :faq="faq" />' />
                <vue-code-block class="u-mb" code='<McFaq :faq="faq" />' />
            </div>
        </div>
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">
            Single-select autocomplete search input
        </h2>
        <div class="l-grid --2-cols u-mb">
            <div>
                <mc-single-complete
                    @select="onSinglecompleteSelect"
                    :items="[
                        { name: 'Emily-Jane Crouch' },
                        { name: 'Sonya Underwood' },
                        { name: 'Jasmin Peck' },
                        { name: 'Katerina Nava' },
                        { name: 'Kaitlyn Morley' },
                        { name: 'Sapphire Dougherty' },
                        { name: 'Albert Woolley' },
                        { name: 'Haniya Wyatt' },
                        { name: 'Ailish Marquez' },
                        { name: 'Daphne Morse' },
                    ]"
                    placeholder="Zoeken"
                    v-if="!isLoading"
                />
            </div>
            <div>
                <vue-code-block
                    class="u-mb"
                    code='<mc-single-complete
                    @select="onSinglecompleteSelect"
                    :items="schools"
                    placeholder="Zoeken"
                    v-if="!isLoading"
                /> '
                />

                <vue-code-block
                    code="data() {
    return {
        autocompleteSelection: [],
    }
},
methods: {
    onAutocompleteSelect(value) {
        this.autocompleteSelection.push(value)
    },
}, "
                />
            </div>
        </div>
        <p class="c-p">Your selection: {{ singleCompleteSelection }}</p>
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">
            Multi-select autocomplete search input
        </h2>
        <div class="l-grid --2-cols u-mb">
            <div>
                <mc-autocomplete
                    @select="onAutocompleteSelect"
                    @unselect="onAutocompleteUnselect"
                    placeholder="Zoeken"
                    :init-select-open="true"
                    :keep-select-open="true"
                    :show-search-icon="true"
                    :number-of-columns="3"
                    :multiple="true"
                    :items="[
                        'Emily-Jane Crouch',
                        'Sonya Underwood',
                        'Jasmin Peck',
                        'Katerina Nava',
                        'Kaitlyn Morley',
                        'Sapphire Dougherty',
                        'Albert Woolley',
                        'Haniya Wyatt',
                        'Ailish Marquez',
                        'Daphne Morse',
                    ]"
                    :init-selected-items="[]"
                />
            </div>
            <div>
                <vue-code-block
                    class="u-mb"
                    code='<mc-autocomplete
    @select="onAutocompleteSelect"
    :items="someArray"
    placeholder="Zoeken"
    :init-select-open="false"
    :keep-select-open="false"
    :show-search-icon="true"
    :number-of-columns="3"
    :multiple="true"
    :init-selected-items="arrayWithSelectedValuesOnInit"
/>

                '
                />
                <p class="c-p u-mb">
                    All props are optional, except "items".
                    "init-selected-items" can be used to set values in the
                    "items" array on initialization, when the mcAutoComplete is
                    used for "update" features, with already saved selections.
                </p>
                <p class="c-p u-mb">
                    When an option is selected, the value is being sent to the
                    parent through an emit event. In the parent, you can use an
                    "onAutocompleteSelect()" method to do something with the
                    selected value, like this for example:
                </p>

                <vue-code-block
                    code="data() {
    return {
        autocompleteSelection: [],
    }
},
methods: {
    onAutocompleteSelect(value) {
        this.autocompleteSelection.push(value)
    },
    onAutocompleteUnselect(i) {
        this.autocompleteSelection.splice(i, 1)
    },
}, "
                />
            </div>
        </div>
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Change password</h2>
        <div class="l-grid --2-cols u-mb">
            <div>
                <mc-set-password @setnewpassword="password = $event" />
            </div>
            <vue-code-block
                code='<mc-set-password
    @setnewpassword="password = $event"
/>'
            />
        </div>

        <div class="l-grid --2-cols u-mb">
            <div>
                <mc-set-password
                    new-password
                    @setnewpassword="password = $event"
                />
            </div>
            <vue-code-block
                code='<mc-set-password
    new-password
    @setnewpassword="password = $event"
/>'
            />
        </div>
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Alerts</h2>

        <h3 class="c-heading --xs u-mb">v1 (for projects that use Vuex)</h3>

        <p class="u-mb+">
            Required properties:<br /><br />
            alertType: info, success or error<br />
            text: Any string<br /><br />
            If you don't pass on a closable or duration property, it will use
            the defaults, which are:<br /><br />
            closable: true<br />
            duration: 5000
        </p>

        <div class="l-grid --2-cols u-mb">
            <div>
                <button
                    class="c-button --primary"
                    @click="
                        $store.commit({
                            type: 'addAlert',
                            text:
                                'This is a closable info alert with a duration of 5 seconds, with a custom (emoji) icon',
                            alertType: 'info',
                            image: '/ui-components/img/emojis/😍.png',
                        })
                    "
                >
                    Click to trigger an info alert with a custom icon
                </button>
            </div>
            <vue-code-block
                code='<button
    class="c-button --primary"
    @click="
        $store.commit({
            type: "addAlert",
            text:
                "This is a closable info alert with a duration of 5 seconds, with a custom (emoji) icon",
            alertType: "info",
            image: "/ui-components/img/emojis/😍.png",
        })
    ">
    Click to trigger an info alert
</button>'
            />
        </div>

        <div class="l-grid --2-cols u-mb">
            <div>
                <button
                    class="c-button --primary"
                    @click="
                        $store.commit({
                            type: 'addAlert',
                            text:
                                'This is a closable success alert with a duration of 10 seconds',
                            alertType: 'success',
                            duration: 10000,
                        })
                    "
                >
                    Click to trigger a 10 second success alert
                </button>
            </div>
            <vue-code-block
                code='<button
    class="c-button --primary"
    @click="
        $store.commit({
            type: "addAlert",
            text:
                "This is a closable success alert with a duration of 10 seconds",
            alertType: "success",
            duration: 10000,
        })
    ">
    Click to trigger a 10 second success alert
</button>'
            />
        </div>
        <div class="l-grid --2-cols u-mb">
            <div>
                <button
                    class="c-button --primary"
                    @click="
                        $store.commit({
                            type: 'addAlert',
                            text:
                                'This is a non-closable error alert with a duration of 5 seconds',
                            alertType: 'error',
                            closable: false,
                        })
                    "
                >
                    Click to trigger a non-closable error alert
                </button>
            </div>

            <vue-code-block
                code='<button
    class="c-button --primary"
    @click="
        $store.commit({
            type: "addAlert",
            text:
                "This is a non-closable error alert with a duration of 5 seconds",
            alertType: "error",
            closable: false,
        })
    ">
    Click to trigger an error alert
</button>'
            />
        </div>

        <div class="l-grid --2-cols u-mb">
            <div>
                <button
                    class="c-button --primary"
                    @click="
                        $store.commit({
                            type: 'addAlert',
                            title: 'Undefined error',
                            text:
                                'This is a closable error alert which does not close automatically',
                            alertType: 'error',
                            closable: true,
                            infiniteDuration: true,
                        })
                    "
                >
                    Click to trigger a closable error alert without duration
                </button>
            </div>

            <vue-code-block
                code='<button
    class="c-button --primary"
    @click="
        $store.commit({
            type: "addAlert",
            title: "Undefined error",
            text:
                "This is a closable error alert which does not close automatically",
            alertType: "error",
            closable: true,
            infiniteDuration: true,
        })
    ">
    Click to trigger an error alert
</button>'
            />
        </div>

        <h3 class="c-heading --xs u-mb">v2 (for projects that use Pinia)</h3>

        <p class="u-mb+">
            Required properties:<br /><br />
            message: Any string<br />
            type: "success" | "error"<br />
            closable: boolean<br /><br />
            duration is set to 5000 (5 seconds)
        </p>

        <vue-code-block
            code='<script lang="ts" setup>
import { useAlertStore } from "@/assets/ui-components/pinia-stores/alert" 

const alert = useAlertStore()
</script>

<template>
    <button
        class="c-button --primary"
        @click="
            alert.show("This is a generic error alert", "error", true)
        "
    >
        Show generic error alert
    </button>
</template>'
        />
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Modal</h2>

        <p class="u-mb+">
            A reusable modal component, with a few different options (done with
            props) for size and padding. If you don't pass a "size" prop, it
            will take 90% of the width of the body.
        </p>

        <div class="l-grid --2-cols u-mb">
            <mc-modal>
                <template #trigger>
                    <button class="c-button --primary">
                        Click to open modal (without padding)
                    </button>
                </template>
                <h1>Modal title</h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Est, quia tempore, vitae voluptas nostrum hic perferendis
                    maiores unde facere similique sequi velit rerum et deserunt.
                    Doloribus explicabo blanditiis, veniam molestias!
                </p>
            </mc-modal>
            <vue-code-block
                code="<mc-modal>
    <template #trigger>
        (HTML for the trigger (link/button) here)
    </template>

    (HTML for the content of the modal here)
</mc-modal>"
            />
        </div>

        <div class="l-grid --2-cols u-mb">
            <mc-modal padding>
                <template #trigger>
                    <button class="c-button --primary">
                        Click to open modal (with padding)
                    </button>
                </template>
                <h1>Modal title</h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Est, quia tempore, vitae voluptas nostrum hic perferendis
                    maiores unde facere similique sequi velit rerum et deserunt.
                    Doloribus explicabo blanditiis, veniam molestias!
                </p>
            </mc-modal>
            <vue-code-block
                code="<mc-modal padding>
    <template #trigger>
        (HTML for the trigger (link/button) here)
    </template>

    (HTML for the content of the modal here)
</mc-modal>"
            />
        </div>

        <div class="l-grid --2-cols u-mb">
            <mc-modal padding size="small">
                <template #trigger>
                    <button class="c-button --primary">
                        Click to open modal (small, with padding)
                    </button>
                </template>
                <h1>Modal title</h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Est, quia tempore, vitae voluptas nostrum hic perferendis
                    maiores unde facere similique sequi velit rerum et deserunt.
                    Doloribus explicabo blanditiis, veniam molestias!
                </p>
            </mc-modal>

            <vue-code-block
                code='<mc-modal padding size="small">
    <template #trigger>
        (HTML for the trigger (link/button) here)
    </template>

    (HTML for the content of the modal here)

</mc-modal>'
            />
        </div>

        <div class="l-grid --2-cols u-mb">
            <mc-modal padding size="large">
                <template #trigger>
                    <button class="c-button --primary">
                        Click to open modal (large, with padding)
                    </button>
                </template>
                <h1>Modal title</h1>
                <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Est, quia tempore, vitae voluptas nostrum hic perferendis
                    maiores unde facere similique sequi velit rerum et deserunt.
                    Doloribus explicabo blanditiis, veniam molestias!
                </p>
            </mc-modal>

            <vue-code-block
                code='<mc-modal padding size="large">
    <template #trigger>
        (HTML for the trigger (link/button) here)
    </template>

    (HTML for the content of the modal here)

</mc-modal>'
            />
        </div>
    </div>

    <div class="c-card --box-bg --with-padding u-mb+">
        <h2 class="c-heading --small u-mb">Checkbox</h2>

        <div class="l-grid --2-cols u-mb">
            <mc-checkbox
                label="Checkbox label"
                name="checkbox-name"
                class="u-mb-"
            />
            <vue-code-block
                code='<mc-checkbox
    label="Checkbox label"
    name="checkbox-name"
/>'
            />
        </div>

        <div class="l-grid --2-cols u-mb">
            <mc-checkbox
                label="Checkbox label2"
                name="checkbox-name2"
                class="u-mb"
            />
            <vue-code-block
                code='<mc-checkbox
    label="Checkbox label2"
    name="checkbox-name2"
/>'
            />
        </div>
    </div>

    <div class="c-card --box-bg --with-padding u-mb+ u-p">
        <h2 class="c-heading --small u-mb">Tooltip</h2>

        <p class="c-p u-mb">Tooltip with icon (default)</p>

        <div class="l-grid --2-cols u-mb">
            <div>
                <mc-tooltip>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                </mc-tooltip>
            </div>
            <div>
                <vue-code-block
                    code="<mc-tooltip>
    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
</mc-tooltip>"
                />
            </div>
        </div>

        <p class="c-p u-mb">Tooltip on the bottom</p>

        <div class="l-grid --2-cols u-mb">
            <div>
                <mc-tooltip bottom>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                </mc-tooltip>
            </div>
            <div>
                <vue-code-block
                    code="<mc-tooltip bottom>
    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
</mc-tooltip>"
                />
            </div>
        </div>

        <p class="c-p u-mb">Tooltip with a different generic trigger</p>

        <div class="l-grid --2-cols u-mb">
            <div>
                <mc-tooltip>
                    <template #trigger>
                        <button class="c-button --primary" disabled>
                            Hover over me to see a tooltip with a custom trigger
                        </button>
                    </template>
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                </mc-tooltip>
            </div>
            <div>
                <vue-code-block
                    code='<mc-tooltip>
    <template #trigger>
        <button class="c-button --primary">
            Hover over me to see a tooltip with a custom trigger
        </button>
    </template>
    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
</mc-tooltip>

                    '
                />
            </div>
        </div>
    </div>
    <mc-cookie-bar :marketing-cookies="true" />
</template>

<script>
import CodeBlock from "@/components/CodeBlock.vue"
import IconCard from "@/components/IconCard.vue"
import McAutocomplete from "@/assets/ui-components/vue-components/McAutocomplete.vue"
import McCheckbox from "@/assets/ui-components/vue-components/McCheckbox.vue"
import McModal from "@/assets/ui-components/vue-components/McModal.vue"
import McTooltip from "@/assets/ui-components/vue-components/McTooltip.vue"
import VueCodeBlock from "@/components/VueCodeBlock.vue"
import McSetPassword from "@/assets/ui-components/vue-components/McSetPassword.vue"
import McCookieBar from "@/assets/ui-components/vue-components/McCookieBar.vue"
import McSingleComplete from "@/assets/ui-components/vue-components/McSingleComplete.vue"
import McFaq from "@/assets/ui-components/vue-components/McFaq.vue"

export default {
    name: "Home",
    components: {
        CodeBlock,
        IconCard,
        McAutocomplete,
        McCheckbox,
        McModal,
        McTooltip,
        VueCodeBlock,
        McSetPassword,
        McCookieBar,
        McSingleComplete,
        McFaq,
    },
    data() {
        return {
            colors: [
                { name: "$yellow", hex: "#fdd753" },
                { name: "$dark-yellow", hex: "#daaa00" },
                { name: "$light-yellow", hex: "#fff7dd" },
                { name: "$red", hex: "#d96464" },
                { name: "$light-grey", hex: "#f7f7f7" },
                { name: "$blue", hex: "#229ac9" },
                { name: "$green", hex: "#20c267" },
                { name: "$black", hex: "#1d1d1b" },
                { name: "$white", hex: "#ffffff" },
                { name: "$light-purple", hex: "#f6f5fa" },
            ],
            faqs: [
                {
                    title: "FAQ 1",
                    paragraphs: [
                        "1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad natus ducimus, exercitationem, sequi saepe iusto ut eveniet, nobis quia architecto laboriosam! Fugiat distinctio quia, dolorem soluta, quo quibusdam velit numquam!",
                        "2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad natus ducimus, exercitationem, sequi saepe iusto ut eveniet, nobis quia architecto laboriosam! Fugiat distinctio quia, dolorem soluta, quo quibusdam velit numquam!",
                    ],
                },
                {
                    title: "FAQ 2",
                    paragraphs: [
                        "1 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad natus ducimus, exercitationem, sequi saepe iusto ut eveniet, nobis quia architecto laboriosam! Fugiat distinctio quia, dolorem soluta, quo quibusdam velit numquam!",
                        "2 Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad natus ducimus, exercitationem, sequi saepe iusto ut eveniet, nobis quia architecto laboriosam! Fugiat distinctio quia, dolorem soluta, quo quibusdam velit numquam!",
                    ],
                },
            ],
            autocompleteSelection: [],
            singleCompleteSelection: [],
        }
    },
    methods: {
        onAutocompleteSelect(value) {
            this.autocompleteSelection = []
            this.autocompleteSelection.push(value)
        },
        onAutocompleteUnselect(i) {
            this.autocompleteSelection.splice(i, 1)
        },
        onSinglecompleteSelect(value) {
            this.singleCompleteSelection = []
            this.singleCompleteSelection.push(value)
        },
    },
}
</script>

<style lang="sass" scoped>
.v-menu
    display: flex
    padding: $standard-white-space 0
    position: sticky
    top: 0
    background-color: $main-bg-color
    +z-index(header)

    a
        margin-right: $standard-white-space * 2

.v-colors
    display: grid
    grid-template-columns: 1fr 1fr 1fr 1fr
    grid-gap: $standard-white-space

    &__box
        padding-top: 100%
        height: 0
        +border-radius
        margin-bottom: 5px

.v-icons
    display: grid
    grid-template-columns: repeat(10, 1fr)
    grid-gap: ($standard-white-space * 2) $standard-white-space
    text-align: center
</style>
