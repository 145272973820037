<template>
    <div class="u-mb">
        <label class="c-label u-mb-">{{ passwordLabel }}</label>
        <input
            class="c-input"
            type="password"
            :placeholder="i18n.inputPlaceholder"
            v-model="password"
            :class="notEqual ? '--error' : ''"
            autocomplete="new-password"
        />
        <template v-if="password !== ''">
            <div
                class="mc-password-validation__badge"
                :class="tooShort ? '' : '--correct'"
            >
                {{ i18n.tooShort }}
            </div>
            <div
                class="mc-password-validation__badge"
                :class="noLowercase ? '' : '--correct'"
            >
                {{ i18n.noLowercase }}
            </div>
            <div
                class="mc-password-validation__badge"
                :class="noUppercase ? '' : '--correct'"
            >
                {{ i18n.noUppercase }}
            </div>
            <div
                class="mc-password-validation__badge"
                :class="noNumber ? '' : '--correct'"
            >
                {{ i18n.noNumber }}
            </div>
            <div
                class="mc-password-validation__badge"
                :class="noSymbol ? '' : '--correct'"
            >
                {{ i18n.noSymbol }}
            </div>
        </template>
    </div>
    <div class="u-mb+">
        <label class="c-label u-mb-">
            {{ passwordLabel }} {{ i18n.repeatLabel }}
        </label>
        <input
            class="c-input"
            type="password"
            :placeholder="i18n.inputPlaceholder"
            v-model="passwordRepeat"
            :class="notEqual ? '--error' : ''"
            autocomplete="new-password"
        />
        <p v-if="notEqual && passwordRepeat !== ''" class="c-p --error u-mt-">
            {{ i18n.notEqual }}
        </p>
    </div>
</template>

<script>
export default {
    name: "ChangePassword",
    props: {
        newPassword: {
            type: Boolean,
            required: false,
        },
    },
    emits: ["setnewpassword"],
    data() {
        return {
            i18n: {
                newPasswordLabel: "Nieuw wachtwoord",
                passwordLabel: "Wachtwoord",
                inputPlaceholder: "Wachtwoord",
                tooShort: "min. 8 tekens",
                noLowercase: "1 kleine letter",
                noUppercase: "1 hoofdletter",
                noNumber: "1 cijfer",
                noSymbol: "1 symbool",
                repeatLabel: "herhalen",
                notEqual: "Wachtwoorden zijn niet gelijk",
            },
            password: "",
            passwordRepeat: "",
            passwordsNotEqual: true,
            passwordNotSecure: true,
        }
    },
    computed: {
        passwordLabel() {
            if (this.newPassword) {
                return this.i18n.newPasswordLabel
            } else {
                return this.i18n.passwordLabel
            }
        },
        notEqual() {
            if (this.password === this.passwordRepeat) {
                return false
            } else {
                return true
            }
        },
        notSecure() {
            // Enforce 1 uppercase character, 1 lowercase character, 1 number and 1 special character with a min. length of 8
            let regexp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!-/:-@[-`{-~±§]).{8,}/

            if (this.password.match(regexp)) {
                return false
            } else {
                return true
            }
        },
        tooShort() {
            if (this.password.length < 8) {
                return true
            } else {
                return false
            }
        },
        noLowercase() {
            let regexp = /[a-z]/g

            if (this.password.match(regexp)) {
                return false
            } else {
                return true
            }
        },
        noUppercase() {
            let regexp = /[A-Z]/g

            if (this.password.match(regexp)) {
                return false
            } else {
                return true
            }
        },
        noNumber() {
            let regexp = /[0-9]/g

            if (this.password.match(regexp)) {
                return false
            } else {
                return true
            }
        },
        noSymbol() {
            // Ranges from ! to /, : to @, { to `, { to ~ and completed with ± and §. Based on the ASCII-table.
            let regexp = /[!-/:-@[-`{-~±§]/g

            if (this.password.match(regexp)) {
                return false
            } else {
                return true
            }
        },
        valid() {
            if (this.notSecure === true || this.notEqual === true) {
                return false
            } else {
                return true
            }
        },
    },
    watch: {
        password() {
            this.setPassword()
        },
        passwordRepeat() {
            this.setPassword()
        },
    },
    methods: {
        setPassword() {
            this.$emit("setnewpassword", {
                password: this.password,
                valid: this.valid,
            })
        },
    },
}
</script>

<style lang="sass" scoped>
@import "../sass/variables-and-mixins"

.mc-password-validation
    &__badge
        display: inline-block
        background-color: #fff7da
        +border-radius
        height: 23px
        line-height: 23px
        margin-top: 8px
        margin-right: 4px
        padding: 0 10px
        font-size: $small

        &.\--correct
            background-color: $inverted-bg-color
            color: $inverted-text-color
            +cdn-background-image('icons/checkmark-white.svg')
            background-position: left 8px center
            background-size: auto 40%
            padding-left: 23px
</style>
